<template>
  <div class="game">
    <!-- 地图 -->
    <div class="map"
         id="map"
         @click="handleClickDanosaur">
      <!-- 恐龙 -->
      <div id="danosaur"
           class="danosaur">
        <lottie-player src="https://assets8.lottiefiles.com/datafiles/40aX5db74VvGPWw/data.json"
                       background="transparent"
                       speed="1"
                       style="width: 160px; height: 160px;"
                       loop
                       :controls="false"
                       autoplay></lottie-player>
      </div>
      <!-- 仙人掌 -->
      <div class="cactus"></div>
    </div>
  </div>
</template>

<script>
import { nextTick, onMounted, onUnmounted, ref } from '@vue/runtime-core'
import { useRouter } from 'vue-router'

export default {
  setup () {
    // 地图
    let map
    // 地图宽度
    let mapWidth

    // 恐龙
    let danosaur
    // 恐龙右侧距离地图左侧
    const danosaurRight = 150

    // 地图右边与恐龙安全距离（仙人球与恐龙距离）
    let safeDistince

    // 是否碰撞
    let collide = false

    // 恐龙是否跳起
    const jeep = ref(false)

    let animateCactusTimer
    let generateCactusTimer

    onMounted(() => {
      nextTick(() => {
        danosaur = document.getElementById('danosaur')
        map = document.getElementById('map')
        mapWidth = window.innerWidth
        safeDistince = mapWidth - danosaurRight

        generateCactus()
        animate()
      })
    })

    onUnmounted(() => {
      clearInterval(animateCactusTimer)
      clearInterval(generateCactusTimer)
      animateCactusTimer = null
      generateCactusTimer = null
    })

    const generateCactus = () => {
      generateCactusTimer = setInterval(() => {
        if (Math.random() < 0.5) {
          return
        }
        const cactus = document.createElement('div')
        cactus.setAttribute('class', 'cactus')
        map.appendChild(cactus)
      }, 1800)
    }

    const animate = () => {
      animateCactusTimer = setInterval(() => {
        animateAllCactus()
      }, 20)
    }

    const animateAllCactus = () => {
      const cactusList = document.getElementsByClassName('cactus')
      for (const cactus of cactusList) {
        animateCactus(cactus)
      }
    }

    /**
     * 执行动画
     */
    const animateCactus = (cactus) => {
      // 计算并刷新仙人掌的位置
      const cactusRightStr = cactus.style.right || '-150px'
      const cactusRight = parseInt(cactusRightStr.substring(0, cactusRightStr.length - 2))
      cactus.style.right = (cactusRight + 2) + 'px'

      // 判断是否碰撞
      const cactusLeft = cactusRight + 80
      if (cactusLeft >= safeDistince &&
        cactusRight <= safeDistince &&
        !jeep.value &&
        !collide) {
        collide = true

        const confirmEvent = confirm('小恐龙撞到仙人掌了！继续玩嘛？')
        onCinform(confirmEvent)
      }

      // 如果仙人掌超出地图则将其移除
      if (cactusRight > mapWidth) {
        map.removeChild(cactus)
      }
    }

    const router = useRouter()
    const onCinform = (onCinform) => {
      if (onCinform) {
        const cactusList = document.getElementsByClassName('cactus')
        for (let i = 0; i < cactusList.length; i++) {
          const cactus = cactusList[i]
          map.removeChild(cactus)
        }
        collide = false
      } else {
        router.push('/home')
      }
    }

    const handleClickDanosaur = () => {
      jeep.value = true
      setTimeout(() => {
        jeep.value = false
      }, 1600)

      danosaur.animate([
        { transform: 'translateY(0px)' },
        { transform: 'translateY(-300px)' },
        { transform: 'translateY(0px)' }
      ], {
        duration: 1600,
        direction: 'alternate'
      })
    }

    return {
      jeep,
      handleClickDanosaur
    }
  }
}
</script>

<style lang="less">
.game {
  height: 100%;

  .map {
    position: relative;
    height: 400px;
    border-bottom: solid 3px gainsboro;

    .danosaur {
      position: absolute;
      bottom: -30px;
      left: 20px;
      z-index: 2;
    }

    .cactus {
      height: 150px;
      width: 90px;
      background: url('/img/cactus.png') no-repeat;
      background-size: 100% 100%;
      position: absolute;
      right: -100px;
      bottom: 0;
      z-index: 1;
    }
  }
}
</style>
